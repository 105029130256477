@font-face {
  font-family: 'MergeProLight';
  src: url('/fonts/MergeProLightW00-Bold.ttf') format('truetype');
}
.recipe-content-page {
  padding-bottom: 4em;
  .recipeArea {
    width:100%;
    max-width: 960px;
    margin: 3em auto;
  }
  
  h2.sectionTitle {
    font-size: 2rem;
    text-align: left;
    padding-bottom: 0.5em;
  }
  img.sectionTitle {
    width: 100%;
    padding: 2em 0.5em;
  }

  .recipe-content {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(45%, auto));
    grid-template-rows: auto;
    grid-gap: 2.5%;
    justify-content: center;
    margin: 0 auto 0;
  }

  .recipe-section {
    display: flex;
    flex-direction: column;
    img {
      width: 100%;
    }
  }

  .recipeBlock {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(48%, auto));
    grid-template-rows: auto;
    grid-gap: 1%;
    justify-content: center;
    .buttonContent {
      display: flex;
    }
    .item-btn {     
      display: inline-flex;
      justify-content: center;
      align-items: center; 
      border-radius: 10px;
      width: 46%;
      margin: 0 2%;
      padding: 10px 5px;
      a {
        text-transform: uppercase;
        font-size: 10px;
        font-weight: 700;
        text-decoration: none;
      }
    }
  }
  .clearer {
    clear: both;
    display: block;
    height: 1px;
  }
}
// RecipeDetail
.recipeDetail{
  padding-bottom: 3em;
  .header img {
    width: 100%;
  }
  .subheader {
    padding: 1em 0 1.75em;
    .subheaderContent{
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      padding: 2em;
      width: 100%;
      max-width: 1250px;
      margin: 0 auto;
      .social {
        width: 50%;
        display: flex;
        flex-direction: column;
        .socialIcons {
          margin-top: 0.75em;
          img {
            padding: 0 0.25em;
            cursor: pointer;
          }
        }
      }
      .times {
        width: 50%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        div {
          width: 33%;
          margin: 0 auto;
          padding: 1em;
          img {
            width: 100%;
            max-width: 150px;
          }
        }
      }
    }
    img.border {
      width: 98%;
      margin: 0 auto
    }
  }
  .detail {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 90%;
    margin: 0 auto;
    padding: 2em 0;
    .ingredients,
    .directions {
      width: 50%;
      text-align: left;
    }
    .ingredients {
      p {
        margin-bottom: 0.5em;
      }
      h4 {
        margin: 1.5em 0 0.75em;
        font-size: 1.125em;
      }
    }
    .directions {
      p {
        margin-top: 0.75em;
      }
    }
  }
}


@media screen and (max-width: 960px) {
  .content-page {
    h2.sectionTitle {
      font-size: 1.75rem;
    }
  }
  .recipe-content-page {
   padding: 0 1em;
  }
  .recipeDetail {
    .subheader .subheaderContent {
      flex-direction: column;
      .social {
        width: 100%;
        margin-bottom: 2em;
      }
      .times {
        width: 100%;
      }
    }
  }
}
@media screen and (max-width:767px) {
  .recipe-content-page {
    .recipe-content {
      .recipeBlock {
        grid-template-columns: repeat(auto-fit, minmax(50%, auto));
      }
    }
   }
  .recipeDetail {
    .detail {
      width: 90%;
      margin: 0 5%;
      flex-direction: column;
      .ingredients,
      .directions {
        width: 100%;
      }
      .directions {
        margin-top: 3em;
      }
    }
  }
}
@media screen and (max-width:450px) {
  .recipeDetail {
    .subheader .subheaderContent {
      .times {
        flex-direction: column;
        div {
          width: 100%;
        }
      }
    }
  }
}

// custom styling per program
.mypantryplanner {
  .recipe-content-page {
    background: #201d1e;
  }
  .recipe-item {
    .buttonContent {
      background: #f4e2ce;
      padding: 10px;
      border-radius: 0 0 3px 3px;
    }
    .cart-btn {
      font-family:'MergeProLight';
      background: #55ba47;
      border: 3px solid #55ba47;
      a {
        color: #f4e2ce !important;
      }
      &:hover {
        background: transparent;
        a {
          color: #55ba47 !important;
        }
      }
    }
    .recipe-btn {
      font-family:'MergeProLight';
      background: transparent;
      border: 3px solid #55ba47;
      a {
        color: #55ba47 !important;
      }
      &:hover {
        background: #55ba47;
        a {
          color: #f4e2ce !important;
        }
      }
    }
  }
  .recipeDetail {
    .border {
      background: #f4e2ce;
      overflow: hidden;
      border: none !important;
      width: 98%;
      margin: 0 auto;
    }
    .subheader {
      background: #f4e2ce;
      h3 {
        font-family:'MergeProLight';
        font-size: 1.65em;
      }
    }
    .detail {
      .ingredients {
        h3 {
          font-family:'MergeProLight';
          color: #00bfce;
        }
        button.cart-btn {
          font-family:'MergeProLight';
          background: #55ba47;
          border: 3px solid #55ba47;
          margin: 1.25em 0;
          a {
            color: #fff !important;
          }
          &:hover {
            background: transparent;
            a {
              color: #55ba47 !important;
            }
          }
        }
      }
      .directions {
        h3 {
          font-family:'MergeProLight';
          color: #ff3c52;
        }
        div.number {
          font-family:'MergeProLight';
          background: #00bfce;
          color:#fff;
          border-radius: 50%;
          -moz-border-radius: 50%;
          -webkit-border-radius: 50%;
          display: inline-block;
          font-size: 2.25em;
          font-weight: bold;
          line-height: 45px;
          margin-right: 5px;
          text-align: center;
          width: 45px;
        }
      }
    }
  }
}