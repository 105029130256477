.heroImg {
  width: 100%;
}
.gardenImg {
  max-width: 100%;
  image-rendering: -webkit-optimize-contrast;
  padding: 1em;
}
@media screen and(max-width:675px) {
  .gardenImg {
    width: 100%;
  }
}


// custom styling for programs
.mypantryplanner {
  .header {
    background: #201d1e;
  }
}